$(document).ready(function() {
  

  $('.wrapper-product-items .col-md-4').each(function(indx, element){

    var current_el_right_side = $(element).find('.box-description__right-side');
    var current_el_left_side = $(element).find('.box-description__left-side');

    var number_current_el_right_side = $(current_el_right_side).innerHeight();
    var number_current_el_left_side = $(current_el_left_side).innerHeight();


    if (number_current_el_left_side <= number_current_el_right_side) {
      
      $(current_el_left_side).css('minHeight', number_current_el_right_side);
    }
    
 
  });
  
});