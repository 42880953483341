function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

$(document).ready(function(){
  $("body").on("submit",".add-to-cart-global",submitCartOuter);
  function submitCartOuter(e){
      e.preventDefault();
      ///////////////////
      $.post($(this).attr('action'),$(this).serialize());
      $("#continueToCartPopup").modal('show');
      ///////////////////
      updateCart();
      return false;
  }


  var updateCart = debounce(function() {
    $.get( "index.php",
    { dir: "site",
      page: "orders",
      op: "ajaxFloatingCart"
    }).done(function( data ) {
        $(".header-desktop-top-shopcart__circle span").text(data);
    });
  }, 300);


  var floatingCartPost = debounce(function() {
    $(".wrap-products .floating-cart-loader").removeClass('hidden');
    $.post($("#floating_cart_form").attr('action'),$("#floating_cart_form").serialize()).done(function( data ) {
      $("#prod-main").html(($(data).find("#prod-main").html()));
      $(".update-cart-items").text($(data).find(".update-cart-items").text());
      $(".update-cart-items").val($(data).find(".update-cart-items").text());
      $(".cart-total-floating").text($(data).find(".cart-total-floating").text());
      $(".prod-price-buy").attr('href',$(data).find(".prod-price-buy").attr('href'));
      $(".cart-total-main").val($(data).find(".cart-total-main").val());
      $("input[name='cart_db[id]']").val($(data).find("input[name='cart_db[id]']").val());
      if(!$(".wrap-products .floating-cart-loader").hasClass('hidden')){
        $(".wrap-products .floating-cart-loader").addClass('hidden');
      }
      initCartBtn();
    });
  }, 300);

    if($("#floating_cart_form").length > 0){
      $("body").on('click','#prod-main .remove-cart-item',floatingCartPost);
      $("body").on('change','#prod-main .input-number',floatingCartPost);
    }

    var updateNormalCart = debounce(function(e) {
      e.preventDefault();

      $(".loader-bg").removeClass('hidden');
      $.post($("#cart-normal-form").attr('action'),$("#cart-normal-form").serialize()).done(function( data ) {
          $("#cart-normal-form").html($(data).find("#cart-normal-form").html());
          setTimeout(function(){$(".loader-bg").addClass('hidden');},700);
      });

      return false;
    }, 300);

    $("body").on('click','#cart-normal-form .remove-cart-item',updateNormalCart);
    $("body").on('click','#cart-normal-form .cart-refreash',updateNormalCart);
    $("body").on('change','#cart-normal-form .input-number',updateNormalCart);
    $("body").on('change','#cart-normal-form .cart-deliviery',updateNormalCart);
    $("body").on('change','#cart-normal-form .discselect',updateNormalCart);
    $("body").on('click','#cart-normal-form .btn-cart-update',updateNormalCart);

    function initCartBtn(){
      if($(".cart-total-floating").length > 0){
        var current = parseFloat($(".cart-total-main").val());
        if(isNaN(current)){
          current = 0;
        }
        if (current <= 0){
          $(".prod-price-buy").addClass('disabled-cart-btn');
          $(".disabled-cart-btn").tooltip();
          $(".disabled-cart-btn").click(function(){

          });
        }else{
          $(".disabled-cart-btn").off();
          $(".disabled-cart-btn").off('.tooltip');
          $(".disabled-cart-btn").tooltip('destroy');
          $(".prod-price-buy").removeClass('disabled-cart-btn');
        }
      }
    }
});




$("body").on('click','.btn-number',function(e){
    e.preventDefault();

    fieldName = $(this).attr('data-field');
    type      = $(this).attr('data-type');
    var input = $("input[name='"+fieldName+"']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
        if(type == 'minus') {

            if(currentVal > input.attr('min')) {
                input.val(currentVal - 1).change();
            }
            if(parseInt(input.val()) == input.attr('min')) {
                $(this).attr('disabled', true);
            }

        } else if(type == 'plus') {

            if(currentVal < input.attr('max')) {
                input.val(currentVal + 1).change();
            }
            if(parseInt(input.val()) == input.attr('max')) {
                $(this).attr('disabled', true);
            }

        }
    } else {
        input.val(0);
    }
});
$("body").on('focusin','.input-number',function(){
   $(this).data('oldValue', $(this).val());
});
$("body").on('change','.input-number',function() {
  minValue =  parseInt($(this).attr('min'));
  maxValue =  parseInt($(this).attr('max'));
  valueCurrent = parseInt($(this).val());

  name = $(this).attr('name');
  if(valueCurrent >= minValue) {
      $(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
  } else {
      $(this).val($(this).data('oldValue'));
  }
  if(valueCurrent <= maxValue) {
      $(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
  } else {
      $(this).val($(this).data('oldValue'));
  }
});
$("body").on('keydown','.input-number',function (e) {
  // Allow: backspace, delete, tab, escape, enter and .
  if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
       // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
       // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
           // let it happen, don't do anything
           return;
  }
  // Ensure that it is a number and stop the keypress
  if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
  }
});
