$(document).ready(function(){

  $('body').on('submit','.js-validate-form', function (e) {
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
    } else {
      $.ajax({
        type: "POST",
        url: $('.js-validate-form').attr('action'),
        data: $('.js-validate-form').serialize(),
        dataType: 'json',
        success: function(data){
          if(data.status == "success"){
            $('.js-validate-form .msg-success').removeClass('hidden');
            $('.js-validate-form input:not(.js-validate-form--send)').val("");
  
            setTimeout(function() {
              $('.js-validate-form .msg-success').addClass('hidden');
            }, 3000);
          }else{
  
          }
        }
      });
    }
    return false;
  });

	$('[placeholder]').focus(function() {
    var input = $(this);
    if (input.val() == input.attr('placeholder')) {
      input.val('');
      input.removeClass('placeholder');
    }
  }).blur(function() {
    var input = $(this);
    if (input.val() == '' || input.val() == input.attr('placeholder')) {
      input.addClass('placeholder');
      input.val(input.attr('placeholder'));
    }
  }).blur();

  $('[placeholder]').parents('form').submit(function() {
    $(this).find('[placeholder]').each(function() {
      var input = $(this);
      if (input.val() == input.attr('placeholder')) {
        input.val('');
      }
    })
  });

});

