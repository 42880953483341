(function( $ ){
  $('body').on({ 
    mouseenter: function() {
      var el = $(this).children(".calendar-popap");
      var offsetLeft = el.offset().left;
      var width = el.width();
      var windowWidth = $(window).width() - 30;
      if(offsetLeft + width > windowWidth){
        el.removeClass("calendar-popap--default-position");
        el.addClass("calendar-popap--top-position");
      }
    },
    mouseleave: function() {
    }
  }, '.calendar-body .day-events');

  $("body").on("click",".wrap-calendar-header-navi_style-btn",function(){
    var el = $(this);
    var month = el.data("month");
    var year = el.data("year");
    $.get( "index.php?dir=site&page=calendar&op=ajax&month="+month+"&year="+year )
	   .done(function( data ) {
	      $(".wrapper-calendar").html(data);
	  }); 
  });
})( jQuery );
