$(function() {

  var dirRtlFlag = false;
  if ($("html").attr('dir') == 'rtl') {
    dirRtlFlag = true;
  }

  $('#attractionsSlider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    speed: 1000,
    rtl: dirRtlFlag,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    pauseOnHover: false,
		responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
  });

  $('.attractionsGallery').lightGallery({
    thumbnail: true,
    selector: '.attractions-box',
    zoom: false,
  });

});