$(document).ready(function() {
	$("#itemGallery").lightGallery({
		selector: '.sales-item-gal',
		useLeft: true,
		thumbnail:true,
    animateThumb: false,
    showThumbByDefault: false,
		zoom: false,
	});
});
